<template>
    <div>
        <div class="bg-white rounded">
            <div class="d-flex align-items-center p-3 border-bottom">
                <div class="h5 mb-0 bg-green text-white rounded-circle lh-1" style="padding: 0.5em;">01</div>
                <h5 class="m-0 ms-2 text-purple">Relatório Tabulações</h5>
            </div>
            <div class="d-flex justify-content-center p-5" v-if="loadingData">
                <b-spinner></b-spinner>
            </div>
            <div class="p-2" v-else-if="chartData.values && chartData.values.length">
                <!-- <ChartBar :height="125" :data="chartData"/> -->
                <ChartLine :height="this.tabulations?.length > 10 ? 500 : 250" :data="chartData" :borderColor="'#000'" :pointRadius="4" :hitRadius="5" :xAxes="lineChart.xAxes" :yAxes="lineChart.yAxes" :legendLabel="lineChart.legendLabel" :legend="lineChart.legend" :tension="lineChart.tension" />
            </div>
            <div class="text-center text-white p-3" v-else-if="hasSearched">
                Nenhum dado encontrado!
            </div>
            <div class="d-flex align-items-center p-3 w-100">
                <b-row class="m-0 w-100 align-items-end">
                    <b-col class="p-0 pe-3">
                        <b-form-group label="Período">
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.date">
                                <b-form-select-option value="1">Hoje</b-form-select-option>
                                <b-form-select-option value="2">Esta Semana</b-form-select-option>
                                <b-form-select-option value="3">Este Mês</b-form-select-option>
                                <b-form-select-option value="4">Definir</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                         <div class="w-100 mt-2" v-if="filters.date==4">
                            <div class="w-50 pe-1 d-inline-block">
                                <b-form-group label="De:">
                                    <b-form-input type="date" v-model="filters.startDate"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="w-50 ps-1 d-inline-block">
                                <b-form-group label="Até:">
                                    <b-form-input type="date" v-model="filters.endDate"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="p-0 pe-3">
                        <b-form-group>
                            <b-form-radio-group v-model="filters.type">
                                <b-form-radio class="d-inline-flex me-3" value="1">
                                    <div class="ms-2">Departamento</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex" value="2">
                                    <div class="ms-2">Operador</div>
                                </b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-if="filters.type == 1">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-else-if="filters.type == 2">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                        </b-form-select>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" disabled v-else></b-form-select>
                    </b-col>
                    <b-col class="p-0 pe-3">
                        <b-form-group label="Tabulação">
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.reason">
                                <b-form-select-option :value="null">Todos</b-form-select-option>
                                <b-form-select-option v-for="tab in tabulations" :key="tab.id" :value="tab.text">
                                    {{ tab.text }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col class="p-0">
                        <b-button class="w-100" style="margin-top: 1.85em" variant="primary" @click="getData()">Gerar</b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="bg-white rounded mt-3" v-if="hasSearched">
            <div class="d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center">
                    <div class="h5 mb-0 bg-green text-white rounded-circle" style="padding: 0.5em;">02</div>
                    <h5 class="m-0 ms-2 text-purple">Relatório Listagem de Atendimento</h5>
                </div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Atendimentos</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ tableItems.count || 0 }}</div>
                </div>
            </div>
            <div class="border-top">
                <div class="bg-white d-flex justify-content-center p-5" v-if="loadingData">
                    <b-spinner></b-spinner>
                </div>
                <div v-else-if="tableItems && tableItems.length">
                    <b-table class="reportContacts m-0" tbody-tr-class="align-middle" hover head-variant="dark" :items="tableItems" :fields="tableFields">
                        <template #cell(attendanceEndTime)="data">
                            {{ data.item.attendanceEndTime | date('MMM DD [●] HH:mm:ss') }}
                        </template>
                        <template #cell(attendanceStartTime)="data">
                            {{ data.item.attendanceStartTime | date('MMM DD [●] HH:mm:ss') }}
                        </template>
                        <template #head(messages)>{{""}}</template>
                        <template #cell(messages)="data">
                            <b-button class="bg-green border-0 px-4" pill @click="loadMessages(data.item._id,data.item.operatorId)" v-b-modal.contactMessages v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Visualizar Atendimento"><b-icon icon="chat-square-text"></b-icon></b-button>
                        </template>
                        <template #cell(clientName)="data">
                            <div class="d-flex align-items-center">
                                <div class="me-2">
                                    <b-avatar class="bg-secondary text-white" size="3rem" :src="data.item.photoURL"></b-avatar>
                                </div>
                                <div>
                                    {{ data.item.clientName }}
                                </div>
                            </div>
                        </template>
                        <template #cell(departmentId)="data">
                            <div>
                                {{ getDepartmentName(data.item.departmentId) }}
                            </div>
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-between align-items-center px-4 py-3" v-if="tableItems && tableItems.count > 10">
                        <b-pagination class="m-0"
                            :total-rows="tableItems.count"
                            v-model="currentPage"
                            :per-page="perPage"
                            @change="pageChange"
                        ></b-pagination>
                        <span class="pagdescription">
                            <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{(currentPage * perPage > tableItems.count) ? tableItems.count : currentPage * perPage}} de {{ tableItems.count }} atendimentos</div>
                        </span>
                    </div>
                </div>
                <div class="text-center p-3 text-secondary" v-else>
                    Nenhum dado encontrado!
                </div>
            </div>
            <b-modal id="contactMessages" ref="contactMessages" title="Visualizar Atendimento" header-class="py-0" hide-footer>
                <div>
                    <Whatsapp :messages="contactMessages" :height="'72vh'" :mediaURL="mediaURL" />
                </div>
            </b-modal>
        </div>
        <FAB ref="fabs" :screenshotBtn="true" :xlsBtn="true" @exportXlsData="exportXlsData" />
    </div>
</template>

<script>
// import ChartBar from './chartBar.vue'
import ChartLine from './chartLine.vue'
import Whatsapp from './whatsapp.vue'
import api from '../services/apiService.js'
import utils from '../utils/utils.js'
import FAB from './FAB.vue'

export default {
    props: [
        'user', 'timestampDiff', 'mediaURL'
    ],
    components: {
        // ChartBar,
        ChartLine,
        Whatsapp,
        FAB
    },
    data() {
        return {
            filters: {
                date: 3,
                type: 0,
            },
            operators: [],
            departments: [],
            tabulations: [],
            chartData: [],
            tableFields: [
                {
                    key: 'clientName',
                    label: 'Contato',
                },
                {
                    key: 'clientNumber',
                    label: 'Telefone'
                },
                // {
                //     key: 'clientName',
                //     label: 'Notificações',
                // },
                {
                    key: 'departmentId',
                    label: 'Departamento'
                },
                {
                    key: 'completionReason',
                    label: 'Tabulação',
                    sortKey: true,
                },
                {
                    key: 'attendanceStartTime',
                    label: 'Data de início do atendimento',
                    sortKey: true,
                },
                {
                    key: 'attendanceEndTime',
                    label: 'Data de fim do atendimento',
                    sortKey: true,
                },
                {
                    key: 'messages',
                    class: ''
                }
            ],
            tableItems: [],
            currentPage: 1,
            perPage: 10,
            contactMessages: [],
            loadingData: false,
            hasSearched: false,
            lineChart: {
                yAxes: {
                    display: true,
                    ticks: {
                        display: true,
                    },
                    gridLines: {
                        display: true
                    }
                },
                xAxes: {
                    display: true,
                    ticks: {
                        display: true,
                    },
                    gridLines: {
                        display: true
                    }
                },
                legendLabel: true,
                legend: {
                    display: true,
                },
                tension: 0.1
            }
        }
    },
    mounted: function() {
        // this.getData()
        this.getDepartments()
        this.getOperators()
        this.getTabulations()
        this.onCloseModal()
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.contactMessages = []
            })
        },
        async getData(page = 1) {
            if (!this.tabulations || !this.tabulations.length) {
                this.loadingData = false
                let msg = {
                    text: 'Nenhuma tabulação encontrada!',
                    success: false
                }
                return this.$emit('msg',msg)
            }
            this.loadingData = true
            if (!this.filters.typeId)
                this.filters.type = 0
            this.chartData = {}
            const resp = await this.getReportTabulation({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, reason: this.filters.reason, page })
            // console.log('resp report',resp)
            if (resp) {
                this.tableItems = resp
            } else {
                this.tableItems = []
            }
            const resp2 = await this.getReportTabulationCount({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, reason: this.filters.reason })
            // console.log('resp count',resp2)
            if (resp2) {
                const labels = []
                let values = []

                for (const tab of this.tabulations) {
                    values.push({
                        label: tab.text,
                        backgroundColor: utils.generateColor(),
                        data: []
                    })
                }

                for (const el of resp2) {
                    if (el.hour) {
                        const [hours, minutes] = el.hour.split(':')

                        const dateObject = new Date()
                        dateObject.setUTCHours(hours)
                        dateObject.setUTCMinutes(minutes)
                        dateObject.setUTCSeconds(0)
                        dateObject.setUTCMilliseconds(0)

                        labels.push(this.$options.filters.date(dateObject,'HH:mm'))
                        // labels.push(el.hour)
                    } else
                        labels.push(this.$options.filters.date(el.date,'DD/MM/YY',true))
                        
                    if (el.completionReason?.length) {
                        this.tabulations.map(tab => {
                            const count = el.completionReason.filter(x => x == tab.text).length
                            const find = values.find(value => value.label == tab.text)
                            if (find) {
                                find.data.push(count)
                            }
                        })
                    } else
                        values.map(value => value.data.push(0))
                    // values.push(el.count)
                }

                if (resp2?.at(0)?.hour) {
                    const oldLabels = labels.slice()
                    labels.sort(this.compareTimes)

                    const newValues = []

                    for (let value of values) {
                        newValues.push({
                            label: value.label,
                            backgroundColor: value.backgroundColor,
                            data: []
                        })
                    }

                    for (let newLabel of labels) {
                        const oldIndex = oldLabels.indexOf(newLabel)
                        for(let i = 0; i < values.length; i++) {
                            newValues[i].data.push(values[i].data[oldIndex])
                        }
                    }
                    values = newValues
                }

                this.$set(this.chartData, 'labels', labels)
                this.$set(this.chartData, 'values', values)
            }
            this.loadingData = false
            this.hasSearched = true
            return
        },
        compareTimes(time1, time2) {
            const date1 = new Date(`1970-01-01T${time1}:00`)
            const date2 = new Date(`1970-01-01T${time2}:00`)
            return date1 - date2;
        },
        async getReportTabulation(data) {
            const resp = await api.getReportTabulation(data)
            if (resp.statusCode == 200) {
                resp.attendances['count'] = resp.count
                return resp.attendances
            }
            return false
        },
        async getReportTabulationCount(data) {
            const resp = await api.getReportTabulationCount(data)
            if (resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            if (resp.statusCode!=200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }

            if (this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if (resp.statusCode!=200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if (this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        },
        async loadMessages(attendanceId, operatorId) {
            const resp = await api.loadMessages({
                attendance: {
                    _id: attendanceId
                },
                operatorId
            })

            if (resp.statusCode === 200) {
                resp.messages.messages.map(el => {
                    if (this.timestampDiff > 100)
                        el.timestamp -= this.timestampDiff
    
                    if (el.timestamp > 9999999999)
                        el.timestamp /= 1000
                })
    
                this.contactMessages = resp.messages.messages
            }
        },
        async getTabulations() {
            const resp = await api.getTabulations(this.user.channelId || this.user.roleId)
            if (resp.statusCode == 200) {
                this.tabulations = resp.tabulations
            } else {
                this.loadingData = false
                this.$emit('msg', {
                    text: 'Nenhuma tabulação encontrada!',
                    success: false
                })
            }
        },
        pageChange(page) {
            if (page != this.currentPage) {
                this.currentPage = page
                this.getData(page)
            }
        },
        async exportXlsData() {
            try {
                const resp = await api.getReportTabulationFull({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, attendanceStatus: this.filters.attendanceStatus, reason: this.filters.reason })
                if (resp.statusCode === 200) {
                    const model = resp.attendances?.map(d=>{
                        const model = {
                            Contato: d.clientName,
                            Whatsapp: d.clientNumber,
                            Departamento: this.getDepartmentName(d.departmentId),
                            Tabulacao: d.completionReason,
                            Inicio_atendimento: this.$options.filters.date(d.attendanceStartTime,'DD/MM/YY HH:mm:ss'),
                            Fim_atendimento: this.$options.filters.date(d.attendanceEndTime,'DD/MM/YY HH:mm:ss'),
                            Operador: d.operatorName
                        }
                        return model
                    })
                    const xls = utils.convertToXLS(model)
                    utils.downloadXLS(xls, `Relatorio-Tabulacoes-${new Date().getTime()}`)
                } else {
                    console.error(resp)
                }
            } catch(error) {
                console.error(error)
            }
            this.$refs.fabs.$emit('exportedXls', true)
        },
        getDepartmentName(departmentId) {
            if (departmentId && this.departments?.length) {
                const department = this.departments.find(el => el._id == departmentId)
                if (department)
                    return department.name
            }
            return null
        },
    },
}
</script>

<style>
    .reportContacts td, .reportContacts th{
        padding: 1em 1.5em !important;
    }
</style>

<style scoped>

</style>